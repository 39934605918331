<template>
  <div>
    <data-table
      @updatePage="getData($event)"
      :headers="headers"
      :data="data.data"
      :meta="data.meta"
      :loader="loader"
    >
      <template #header:status>
        {{ $t("status") }}
      </template>
      <template #status="{item}">
        <div class="d-flex justify-center align-center">
          <v-switch
            v-if="item.status == 'active'"
            @change="changeStatus(item)"
            inset
            v-model="statusTrue"
          ></v-switch>
          <v-switch
            v-else
            @change="changeStatus(item)"
            inset
            v-model="statusFalse"
          ></v-switch>
        </div>
      </template>
    </data-table>
  </div>
</template>
<script>
import { Lesson } from "../../../../store/teacher/lesson";
import { eduClass } from "../../../../store/teacher/eduClass/index";
export default {
  props: ["id"],
  data() {
    return {
      Lesson,
      eduClass,
      headers: {
        id: {
          name: "id",
          keys: ["id"],
        },

        name: {
          name: "name",
          keys: ["name"],
        },
        type: {
          name: "type",
          keys: ["type"],
        },
        created_at: {
          name: "created_at",
          keys: ["created_at"],
        },
        status: {
          slot: "status",
        },
      },
      statusTrue: true,
      statusFalse: false,
    };
  },
  methods: {
    getData() {
      Lesson.get({ edu_class_id: this.id });
    },
    changeStatus(item) {
      let status = item.status == "active" ? "locked" : "active";
      Lesson.edit({
        id: item.id,
        payload: {
          status: status,
          edu_class_id: this.id,
        },
        onSuccess: () => {
          item.status = status;
        },
      });
    },
  },
  computed: {
    data() {
      return Lesson.tableData;
    },
    loader() {
      return Lesson.getState.loading;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
