<template>
  <div>
    <lesson :id="id" />
  </div>
</template>
<script>
import lesson from "../lesson/index.vue";
export default {
  components: {
    lesson,
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
};
</script>
